import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as Rx from "rxjs";


@Injectable({
  providedIn: 'root'
})

export class UserService {
  currentUser = new Rx.BehaviorSubject({});
  orderHistory = new Rx.BehaviorSubject({});
  uid: any;
  mobile: any;
  userId: any;
  email: any;
  url: any = 'https://us-central1-spotyourdeal-38ab2.cloudfunctions.net/api/'
  //url: any='http://192.168.1.54:3000'
  constructor(
    public http: HttpClient,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth
  ) {}

  async initialize(uid) {

    let that = this;

    this.afs.collection('users').doc(uid).valueChanges().subscribe(async (resp: any) => {
      that.currentUser.next(resp)
    })

    this.afs.collection('orderHistory', ref => ref
        .orderBy('timestamp', 'desc').limit(200))
      .valueChanges().subscribe(async (orderHistory: any) => {
        this.orderHistory.next(orderHistory)
      })

    // this.afs.collection('orderHistory', ref => ref.where('timestamp','>=' , 1651622400)
    //     .orderBy('timestamp', 'desc').limit(100))
    //   .valueChanges().subscribe(async (orderHistory: any) => {
    //     console.log("orderHistory count ",orderHistory.length)
    //   })
  }
}
