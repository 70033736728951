import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'stores',
    loadChildren: () => import('./pages/stores/stores.module').then( m => m.StoresPageModule)
  },
  {
    path: 'delivery',
    loadChildren: () => import('./pages/delivery/delivery.module').then( m => m.DeliveryPageModule)
  },
  {
    path: 'add-delivery',
    loadChildren: () => import('./pages/add-delivery/add-delivery.module').then( m => m.AddDeliveryPageModule)
  },
  {
    path: 'store-detail/:id',
    loadChildren: () => import('./pages/store-detail/store-detail.module').then( m => m.StoreDetailPageModule)
  },
  {
    path: 'product-list/:storeId',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'product-detail/:id',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'add-product/:id',
    loadChildren: () => import('./pages/add-product/add-product.module').then( m => m.AddProductPageModule)
  },
  {
    path: 'promotions',
    loadChildren: () => import('./pages/promotions/promotions.module').then( m => m.PromotionsPageModule)
  },
  {
    path: 'add-banner',
    loadChildren: () => import('./pages/add-banner/add-banner.module').then( m => m.AddBannerPageModule)
  },
  {
    path: 'update-banner/:item',
    loadChildren: () => import('./pages/update-banner/update-banner.module').then( m => m.UpdateBannerPageModule)
  },
  {
    path: 'create-order',
    loadChildren: () => import('./pages/create-order/create-order.module').then( m => m.CreateOrderPageModule)
  },
  {
    path: 'search-product',
    loadChildren: () => import('./pages/search-product/search-product.module').then( m => m.SearchProductPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./pages/order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'send-promotions/:id',
    loadChildren: () => import('./pages/send-promotions/send-promotions.module').then( m => m.SendPromotionsPageModule)
  },
  {
    path: 'display-promotion',
    loadChildren: () => import('./pages/display-promotion/display-promotion.module').then( m => m.DisplayPromotionPageModule)
  },
  {
    path: 'sendto-sap',
    loadChildren: () => import('./pages/sendto-sap/sendto-sap.module').then( m => m.SendtoSapPageModule)
  },
  {
    path: 'whatsapp-promotions',
    loadChildren: () => import('./pages/whatsapp-promotions/whatsapp-promotions.module').then( m => m.WhatsappPromotionsPageModule)
  },
  {
    path: 'sms-promotions',
    loadChildren: () => import('./pages/sms-promotions/sms-promotions.module').then( m => m.SmsPromotionsPageModule)
  },
  {
    path: 'create-banner/:id',
    loadChildren: () => import('./pages/create-banner/create-banner.module').then( m => m.CreateBannerPageModule)
  },
  {
    path: 'customer-support/:id',
    loadChildren: () => import('./pages/customer-support/customer-support.module').then( m => m.CustomerSupportPageModule)
  },
  {
    path: 'category-detail/:id',
    loadChildren: () => import('./pages/category-detail/category-detail.module').then( m => m.CategoryDetailPageModule)
  },
  {
    path: 'sub-category-detail/:id',
    loadChildren: () => import('./pages/sub-category-detail/sub-category-detail.module').then( m => m.SubCategoryDetailPageModule)
  },
  {
    path: 'create-category/:id',
    loadChildren: () => import('./pages/create-category/create-category.module').then( m => m.CreateCategoryPageModule)
  },
  {
    path: 'create-subcategory/:id',
    loadChildren: () => import('./pages/create-subcategory/create-subcategory.module').then( m => m.CreateSubcategoryPageModule)
  },
  {
    path: 'all-promotion',
    loadChildren: () => import('./pages/all-promotion/all-promotion.module').then( m => m.AllPromotionPageModule)
  },
  {
    path: 'coupon-code',
    loadChildren: () => import('./pages/coupon-code/coupon-code.module').then( m => m.CouponCodePageModule)
  },
  {
    path: 'fetch-coupon',
    loadChildren: () => import('./pages/fetch-coupon/fetch-coupon.module').then( m => m.FetchCouponPageModule)
  },
  {
    path: 'my-subs-pro',
    loadChildren: () => import('./pages/my-subs-pro/my-subs-pro.module').then( m => m.MySubsProPageModule)
  },
  {
    path: 'inventory-create/:id',
    loadChildren: () => import('./pages/movisio/inventory-create/inventory-create.module').then( m => m.InventoryCreatePageModule)
  },
  {
    path: 'inventory-home',
    loadChildren: () => import('./pages/movisio/inventory-home/inventory-home.module').then( m => m.InventoryHomePageModule)
  },
  {
    path: 'plm-create',
    loadChildren: () => import('./pages/movisio/plm-create/plm-create.module').then( m => m.PlmCreatePageModule)
  },
  {
    path: 'plm-home',
    loadChildren: () => import('./pages/movisio/plm-home/plm-home.module').then( m => m.PlmHomePageModule)
  },
  {
    path: 'ad-store/:id',
    loadChildren: () => import('./pages/movisio/ad-store/ad-store.module').then( m => m.AdStorePageModule)
  },
  {
    path: 'stores-list',
    loadChildren: () => import('./pages/movisio/stores-list/stores-list.module').then( m => m.StoresListPageModule)
  },
  {
    path: 'pending-req/:id',
    loadChildren: () => import('./pages/movisio/pending-req/pending-req.module').then( m => m.PendingReqPageModule)
  },
  {
    path: 'req-inventory',
    loadChildren: () => import('./pages/movisio/req-inventory/req-inventory.module').then( m => m.ReqInventoryPageModule)
  },








];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
