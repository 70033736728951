import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireAuth, } from '@angular/fire/compat/auth';
import { UserService } from './user.service';
import firebase from 'firebase/compat/app';
import { getAuth, signInWithCredential, linkWithCredential, OAuthProvider } from "firebase/auth";
import { FirebaseAuthentication } from '@awesome-cordova-plugins/firebase-authentication/ngx';
import * as Rx from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loginMethod: any;
  phoneNumber: any;
  mobile: any;
   url: any = 'https://us-central1-spotyourdeal-38ab2.cloudfunctions.net/api/'
 // url: any = 'https://f348-223-233-74-2.ngrok.io/'
  constructor(
    private firebaseAuthentication: FirebaseAuthentication,
    private afAuth: AngularFireAuth,
    private http: HttpClient
  ) {}


  login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.afAuth
        .signInWithEmailAndPassword(email, password)
        .then(res => {
          resolve(res);
          console.log('You are Successfully signed in!', res);
        }, error => {
          reject(error)
          console.log('Something is wrong:', error.message);
        });
    })
  }


}
